import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createCloseShareModalAction } from 'redux/actions/ShareModal';
import { CloseButton, Icon } from 'ComponentLibrary';
import { useGetPhotosForCurrentLot } from 'utilities/HooksAndSelectors';
import { isFirefox } from 'react-device-detect';
import Constants from 'Constants';
import './ShareModal.scss';
import { createClosePhotoModalAction } from 'redux/actions/PhotoModal/PhotoModalActions';
import { useNavigate, useLocation } from 'react-router-dom';

const ShareModal = () => {
    const [copied, setCopied] = useState(false);
    const dispatch = useDispatch();
    const shareState = useSelector((reduxState) => reduxState.shareState);
    const photoModalState = useSelector((state) => state.photoModalState);
    const { currentPageNumber } = photoModalState;
    const photos = useGetPhotosForCurrentLot();
    const { isOpen } = shareState;
    const ref = useRef(null);
    const shareRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const keyPress = (event) => {
        if (event.keyCode === 27) {
            event.preventDefault();
            event.stopPropagation();
            if (!isOpen && photoModalState.isOpen) {
                if (location.pathname.indexOf('/dashboard') >= 0) {
                    navigate('/dashboard');
                } else {
                    navigate('/photos');
                }
                dispatch(createClosePhotoModalAction());
            } else {
                dispatch(createCloseShareModalAction());
            }
            return false;
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', keyPress);
        return () => {
            window.removeEventListener('keydown', keyPress)
        };
    });

    const windowClick = (e) => {
        const { x, y } = e;
        const { top, right, left, bottom } = e.target.getBoundingClientRect();
        if (x < left || x > right || y < top || y > bottom) {
            e.preventDefault();
            dispatch(createCloseShareModalAction());
        }
        return false;
    };
    useEffect(() => {
        if (isOpen && ref.current) {
            if (ref && ref.current && !ref.current.hasAttribute('open') && ref.current.showModal) {
                if (isFirefox) {
                    ref.current.setAttribute('open', 'true');
                }
                try {
                    ref.current.showModal();
                } catch (e) {
                    ref.current.setAttribute('open', 'true');
                }
            }
            window.addEventListener('click', windowClick);

            return () => {
                window.removeEventListener('click', windowClick);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, ref]);

    const copyRef = useRef(null);
    useEffect(() => {
        let intval;
        if (copied && copyRef && copyRef.current) {
            intval = setTimeout(() => {
                if (copyRef && copyRef.current) {
                    copyRef.current.classList.add('copy-info--fade');
                    setCopied(false);
                }
            }, 2000);
        }
        return () => {
            clearTimeout(intval);
        };
    }, [copied, copyRef]);

    if (!isOpen) {
        return null;
    }

    return (
        <dialog className="share-modal" ref={isOpen ? ref : null}>
            <CloseButton
                testId="feedback-modal__close"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(createCloseShareModalAction());
                    return false;
                }}
            />
            <h1 className="modal-title modal-title--small">
                Share this photo to:
            </h1>
            <div className="share-buttons">
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        window.FB.ui(
                            {
                                method: 'share',
                                href: photos[currentPageNumber - 1]
                                    ? photos[currentPageNumber - 1].photoLink
                                    : null,
                            },
                            (response) => { }
                        );
                        return false;
                    }}
                    className="share-button share-button--fb"
                >
                    Share On Facebook
                    <Icon icon="facebook" color="white" size={1.5} />
                </button>
                <button
                    type="button"
                    onClick={(e) => {
                        e.preventDefault();
                        const windowOptions =
                            'scrollbars=yes,resizable=yes,toolbar=no,location=yes';
                        const width = 550;
                        const height = 420;
                        const winHeight = window.screen.height;
                        const winWidth = window.screen.width;
                        let left;
                        let top;
                        const url = photos[currentPageNumber - 1].photoLink
                            ? photos[currentPageNumber - 1].photoLink
                            : null;
                        const href = `https://twitter.com/intent/tweet?url=${encodeURI(
                            url
                        )}`;

                        left = Math.round(winWidth / 2 - width / 2);
                        top = 0;

                        if (winHeight > height) {
                            top = Math.round(winHeight / 2 - height / 2);
                        }

                        window.open(
                            href,
                            'intent',
                            `${windowOptions},width=${width},height=${height},left=${left},top=${top}`
                        );
                        e.preventDefault();

                        return false;
                    }}
                    className="share-button share-button--twitter"
                >
                    Share On Twitter
                    <Icon icon="twitter" color="white" />
                </button>
                <a
                    className="share-button share-button--email"
                    href={`mailto:?subject=Check%20Out%20My%20Home!&body=${photos[currentPageNumber - 1] &&
                        photos[currentPageNumber - 1].photoLink
                        }`}
                >
                    Share Via Email
                    <Icon icon="email" color="white" />
                </a>
            </div>
            <form>
                <div className="field-wrap">
                    <label>
                        {Constants.pages.photogallery.copy}
                    </label>
                    <div className="input-wrap input-wrap--read-only">
                        <input
                            type="text"
                            ref={shareRef}
                            id="share"
                            className="input input--read-only"
                            name="share"
                            value={
                                photos[currentPageNumber - 1] &&
                                photos[currentPageNumber - 1].photoLink
                            }
                        />

                        <button
                            className="copy-button"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                const emailLink = document.getElementById(
                                    'share'
                                ); // ('.js-emaillink');
                                emailLink.select();
                                if (shareRef && shareRef.current) {
                                    shareRef.current.select();
                                }
                                const range = document.createRange();
                                range.selectNode(emailLink);
                                window.getSelection().addRange(range);

                                try {
                                    // Now that we've selected the anchor text, execute the copy command
                                    document.execCommand('copy');
                                } catch (err) {
                                    // unable to copy
                                }
                                if (copyRef && copyRef.current) {
                                    copyRef.current.classList.remove(
                                        'copy-info--fade'
                                    );
                                }
                                setCopied(true);
                                return false;
                            }}
                        >
                            <div className="copy-button__inner">
                                <Icon icon="copy" />
                            </div>
                            <span className="visuallyhidden">
                                {Constants.pages.photogallery.copy}
                            </span>
                        </button>
                    </div>
                </div>
                <p
                    ref={copyRef}
                    aria-hidden="true"
                    className={`copy-info ${copied && 'copy-info--active'}`}
                >
                    {Constants.pages.photogallery.copied}
                </p>
            </form>
        </dialog>
    );
};

export default ShareModal;
