import React from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Button as LibraryButton, ButtonStyles } from 'ComponentLibrary';
import Constants from 'Constants';
import PropTypes from 'prop-types';
import './ChecklistAccordionHeader.scss';

const ChecklistAccordionHeader = (props) => {
  const {
    label,
    eventKey,
    mode,
    isOpen,
    canAddTask,
    showTaskForm,
    handleToggle,
    handleToggleAddTaskForm,
  } = props;
  const isMobileWindow = window.innerWidth <= 764;
  const checklistModalText = Constants.checklistModal;
  const toggleAddTask = () => {
    if (!isOpen) {
      handleToggle();
    }

    if (!showTaskForm) {
      handleToggleAddTaskForm();
    }
  };
  return (
    <div
      className={`checklist-accordion-header-container ${canAddTask ? 'with-add' : ''
        }`}
      data-testid="checklist-accordion-header-container"
    >
      <Accordion.Button
        className="checklist-accordion-header-toggle"
        as={Button}
        variant="link"
        eventKey={eventKey}
        onClick={handleToggle}
        data-testid="checklist-accordion-header"
      >
        <div
          className={`checklist-accordion-header checklist-accordion-header--${mode} checklist-accordion-header--${isOpen && 'open'
            }`}
        >
          <p className="checklist-header-text">{label}</p>
        </div>
      </Accordion.Button>
      {canAddTask && !isMobileWindow && (
        <LibraryButton
          as="button"
          icon="largePlus"
          addClass="add-task-button"
          testId="add-task-button"
          bold
          text={checklistModalText.addTaskButtonText}
          style={ButtonStyles.TextOnlyLink}
          onClick={toggleAddTask}
        />
      )}
    </div>
  );
};
ChecklistAccordionHeader.propTypes = {
  label: PropTypes.string.isRequired,
  eventKey: PropTypes.string,
  mode: PropTypes.string.isRequired,
};
export default ChecklistAccordionHeader;
