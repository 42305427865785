import React from 'react';
import { Text, Button, ButtonStyles } from 'ComponentLibrary';
import {
    useLocalStorage,
    useLockBodyScroll,
} from 'utilities/HooksAndSelectors';
import PropTypes from 'prop-types';
import './FatalError.scss';
import Constants from 'Constants';
import { useSelector } from 'react-redux';

export default function FatalError(props) {
    const { error } = props;
    const [hasRefreshed, setHasRefreshed] = useLocalStorage(
        'hasRefreshed',
        false
    );
    const showRefresh = !hasRefreshed && !error.disallowRefresh;

    const user = useSelector((reduxState) => reduxState.userState);
    let button = null;
    if (user.userDetails.errorScreen) {
        button = (
            <a
                className="button primary-button"
                data-testid="error-screen-button"
                href={user.userDetails.errorScreen.link}
            >
                {user.userDetails.errorScreen.text}
            </a>
        );
    }
    useLockBodyScroll();

    const handleRefresh = () => {
        setHasRefreshed(true);
        window.location.reload();
    };

    return (
        <div
            className="fatal-error-container"
            data-testid="fatal-error-container"
        >
            <div className="fatal-error-content">
                <Text type="block" variant="large">
                    {error.message}
                </Text>
                {showRefresh ? (
                    <React.Fragment>
                        <Text type="block" variant="large">
                            {Constants.global.errors.fatal.refresh}
                        </Text>
                        <Button
                            selected
                            as="button"
                            style={ButtonStyles.PrimaryButton}
                            text={Constants.global.errors.fatal.refreshButton}
                            onClick={handleRefresh}
                        />
                    </React.Fragment>
                ) : (
                    !error.hideSecondaryText && (
                        <Text type="block" variant="large">
                            {Constants.global.errors.fatal.failure}
                        </Text>
                    )
                )}
                {button}
            </div>
        </div>
    );
}

FatalError.defaultProps = {
    error: {},
};

FatalError.propTypes = {
    error: PropTypes.object,
};
