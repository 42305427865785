// This file defines all constant strings for the Customer Buyer Portal
// This should be structured based on the pages and types of strings that are being defined.

const titleGenerator = (page) => `${page} – MyMIHome`;

const Constants = {
    global: {
        adminRole: "Employee",
        genericError: "*There is an error with this field.",
        pageTitle: "MyMIHome",
        errors: {
            fatalErrorCodes: [500],
            generic: {
                fail: "There seems to be a problem. Please try again later. If the problem persists, please contact your sales agent.",
                logout: "An error occurred while trying to sign out.",
            },
            fatal: {
                description: "An error occurred.",
                refresh: "Try refreshing this page.",
                failure: "Please try again later.",
                refreshButton: "Refresh",
            },
            redirect: {
                general: "An error occurred.",
                envision: "Invalid Envision credentials",
                wellsfargo:
                    "Something went wrong. If this problem persists, please contact your NHC.",
                warranty:
                    "Your warranty manual will become available after closing.",
                financing:
                    "We are unable to find that information. Please try again later.",
            },
        },
        links: {
            forgotPassword: {
                eventLabel: "forgotPassword",
                label: "Forgot your password?",
                link: `${window._env_.REACT_APP_SITECORE_ROOT}/account/forgot-password`,
            },
            accountRegistration: {
                eventLabel: "accountRegistration",
                label: "Sign up for an account",
                link: `${window._env_.REACT_APP_SITECORE_ROOT}/account/register?from=buyerportal`,
            },
            privacyPolicy: {
                eventLabel: "privacyPolicy",
                label: "Privacy Policy",
                link: `${window._env_.REACT_APP_SITECORE_ROOT}/policies/privacy-policy`,
            },
            termsOfUse: {
                eventLabel: "termsOfUse",
                label: "Terms of Use",
                link: `${window._env_.REACT_APP_SITECORE_ROOT}/policies/terms-of-use`,
            },
        },
        labels: {
            next: "Next",
            done: "Done",
        },
    },
    pages: {
        lotAdmin: {
            noBuyers: "We currently do not have any buyers on this lot.",
            title: "Associated Buyers",
            pageTitle: "Associated Buyers – MyMIHome",
        },
        invite: {
            headingText: "Setup your Buyer Portal account",
            passwordPlaceholder: "Create your password",
            submitButtonText: "Get Started",
            userNameMessage:
                "Note: The email on your contract will be your user name for login",
            expiredMessage:
                "Your invite has expired. We have sent you a new one. Please check your email to access the new link.",
        },
        findLot: {
            labels: ["Name or Address", "Lot", "Recently Viewed"],
            communitySelectPlaceholder: "Select Your Community Name",
            divisionSelectPlaceholder: "Select Your Division Name",
            lotIdError: "Please enter a valid lot id.",
            communityError: "Please select a community.",
            noResults: "No results were found matching your search criteria.",
            instructions:
                "Do not include suffixes like Street, Drive, or Boulevard (ex: 123 Main)",
            divisionError: "Please select a division.",
            addressError: "Please provide an address to search for.",
            lastNameError: "Please provide a last name.",
            searchType: "How would you like to search?",
            searchTypesForLotId: ["12 Digit Job ID", "4 Digit Lot Number"],
            searchTypesForNameAddress: ["Registered Buyer Name", "Address"],
            placeholder: "4 Digit Lot Number",
            resultLabels: {
                name: "Name",
                address: "Address",
                city: "City",
                community: "Community",
                lot: "Lot",
            },
        },
        missing: {
            pageTitle: "Oops! – MyMIHome",
            title: "Oops!",
            body: "We couldn't find what you were looking for.",
            link: {
                href: "/login",
                text: "Back to login",
            },
        },
        admin: {
            title: "Division Configuration",
            pageTitle: "Division Dashboard",
            contactsTitle: "Division Contacts",
            notificationsTitle: "Division Notifications",
            tasksTitle: "Division Tasks",
        },
        warranty: {
            success: {
                headline: "Thank you for submitting your service request!",
                body: "Your submission has been received.",
                cta: {
                    text: "Back to Warranty Dashboard",
                    href: "/dashboard/customer-care-warranty",
                },
            },
            submit: {},
            pageTitle: "Dashboard – Customer Care/Warranty – MyMIHome",
        },
        documents: {
            pageTitle: "Documents – MyMIHome",
            onPageTitle: "Documents",
            introText:
                "Your approved Sales Contract documents are listed below. Note: Some documents are large and can take time to download.",
            noDocuments: "No approved documents are available at this time.",
        },
        checklist: {
            pageTitle: "Checklist – MyMIHome",
        },
        contacts: {
            pageTitle: "Contacts – MyMIHome",
            feedbackHeadline: "My M/I Home App Feedback",
            question: "How is your app working for you?",
            errorMessagePart1:
                "Please contact our Customer Care Department at ",
            errorMessagePart2: " for questions or concerns.",
            sectionTitles: {
                construction: "Construction",
                design: "Design",
                financing: "Loan Application",
                sales: "Sales",
                closing: "Closing",
            },
        },
        login: {
            pageTitle: "Log In – MyMIHome",
        },
        adminLotAssociation: {
            pageTitle: "Look up a home – MyMIHome",
            header: "Let’s look up a home",
            submit: "Go",
            cancel: "Cancel",
            errorMessages: {
                cantFindLot: "We were unable to find that Lot ID",
            },
        },
        lotAssociation: {
            pageTitle: "Link your home – MyMIHome",
        },
        success: {
            pageTitle: "Link Success – MyMIHome",
        },
        dashboard: {
            pageTitle: "Dashboard – MyMIHome",
        },
        photogallery: {
            pageTitle: "Photo Gallery – MyMIHome",
            title: "My Photos",
            filter: "Filter by Tag",
            copy: "Copy Link URL",
            copied: "Copied!",
            selectAll: "Select All Photos",
            cancel: "Cancel",
            download: "Download",
            selectPhotos: {
                part1: "Select",
                part2: "Photos to Download",
            },
            filterModal: {
                title: "Select the tags to filter by:",
                submit: "Apply Filters",
                reset: "Reset Filters",
            },
        },
        financing: {
            pageTitle: "Dashboard – Loan Application – MyMIHome",
        },
        "loan-application": {
            pageTitle: "Dashboard – Loan Application – MyMIHome",
        },
        design: {
            pageTitle: "Dashboard – Design – MyMIHome",
        },
        preconstruction: {
            pageTitle: "Dashboard – Pre-Construction – MyMIHome",
        },
        "pre-construction": {
            pageTitle: "Dashboard – Pre-Construction – MyMIHome",
        },
        construction: {
            pageTitle: "Dashboard – Construction – MyMIHome",
        },
        closing: {
            pageTitle: "Dashboard – Closing – MyMIHome",
        },
        "customer-care-warranty": {
            pageTitle: "Dashboard – Customer Care/Warranty - MyMIHome",
        },
        deposits: {
            pageTitle: "Deposits – MyMIHome",
            headerText: "Your Deposits",
            infoText:
                "Your deposits list presents all sales deposits for building your home. The list may be updated based on contract changes.",
            scheduledHeaderText: "Scheduled Deposits",
            processedHeaderText: "Processed Deposits",
            footnotesHeaderText: "Footnotes",
            tracker: {
                headerText: "Your Contract Deposits Tracker",
                scheduledText: "Scheduled Deposits",
                processedText: "Processed Deposits",
                totalText: "Total Deposits",
            },
            info: {
                headerText: "Important Deposit Information",
                headerInfoText:
                    "Contact your New Home Consultant if you need details on how to make a payment.",
                closingTitleText: "Closing Costs:",
                closingInfoText:
                    "The total deposit amount may not reflect the funds needed at the time of closing. Please contact your Loan Officer for more information about specific closing costs or funding requirements.",
                reflectedTitleText: " What is reflected:",
                refelectedInfoText:
                    "The current view reflects the deposits that have been processed. It can take 1 to 3 business days from the receipt of the deposit for the payment to post your account.",
            },
            table: {
                scheduleDateText: "Scheduled Date",
                processedDateText: "Processed Date",
                amountText: "Amount",
                typeText: "Type",
                paymentText: "Payment",
                noDepositsText: "You Have No Deposits",
                "empty-processed": "You Have No Processed Deposits",
                "empty-scheduled": "You Have No Scheduled Deposits",
            },
        },
        designSelections: {
            titleText: "Your Approved Selections",
            mobileTitleText: "Your Approved Selections",
            infoText:
                "Your approved design selections presents all design choices that have been finalized with M/I Homes. Viewing and individual product will provide all the details of your selection.",
            noSelectionsText:
                "You currently have no approved design selections to view. Check back once your contract has been approved.",
            table: {
                errorText: "We had an error on our end, please try that again.",
                categoryText: "Category",
                nameText: "Name",
                quantityText: "Quantity",
                brandText: "Product Brand",
                numberText: "Product Number",
                colorNotesText: "COLOR NOTES:",
                customerNotesText: "CUSTOMER NOTES:",
                styleNotesText: "STYLE NOTES:",
                optionNotesText: "OPTION NOTES:",
                createDateText: "Create Date",
            },
            accordionSection: {
                errorText: "We had an error on our end, please try that again.",
                quantityLabel: "Quantity:",
                createDateLabel: "Create Date:",
            },
            modalAccordionSection: {
                featuresText: "Features",
                detailsHeader: "Details",
                standardSpecHeader: "Standard Specifications",
                addSpecHeader: "Additional Specifications",
                ownershipHeader: "Ownership",
            },
            itemModal: {
                productNumberText: "Product No.",
                styleColorText: "Style/Color:",
                itemNumberText: "Item No.",
            },
        },
    },
    profileDropDown: {
        adminDashboard: "View Division Dashboard",
        adminLot: "View Lot Dashboard",
        terms: "Terms of Use",
        privacy: "Privacy Policy",
        logout: "Sign Out",
        settings: "Account Settings",
        notifications: "Notifications Settings",
        addLot: "Add a home",
        removeLot: "Remove",
        install: "Install App",
        saveChanges: "Save Changes",
        changeLot: "Change Lot",
        cancel: "Cancel",
        deleteHome: "Delete Home",
        delete: "Delete",
    },
    checklistDropdown: {
        phaseStarter: "Occurs During:",
        viewAll: "View All",
        checklist: "Checklist",
        dueDate: "Due:",
        emptyMessage:
            "Congratulations on completing all the items on your checklist!",
        systemCompleted: "Completed and Verified by M/I Homes",
    },
    notificationsDropdown: {
        title: "Notifications",
    },
    locationCard: {
        hours: "Hours of operation",
        headline: "Office Location",
        closingHeadline: "Closing Location",
    },
    login: {
        text: {
            heading: "Log In to your account",
            loginButton: "Log In",
            notAMember: "Not a member?",
            admin: "Log In As M/I Homes Employee",
        },
        inputs: {
            email: {
                label: "Email Address Used At Contract Signing",
                placeholder: "Enter your e-mail",
                errorMessageRequired: "Email Address is required.",
                errorMessageType: "Please enter a valid email address.",
            },
            password: {
                label: "Password",
                placeholder: "Enter your password",
                errorMessageRequired: "Password is required.",
                errorMessageType: "Please enter a valid password.",
            },
            rememberMe: {
                label: "Remember Me",
            },
        },
        errorMessages: {
            unauthorized: "Your email or password is incorrect.",
        },
    },
    profile: {
        text: {
            title: titleGenerator("Profile"),
        },
    },
    lotAssociation: {
        header: "Let's link up your home",
        placeholder: "Enter your 12-digit Lot ID",
    },
    lotSuccess: {
        text: {
            title: "You've successfully linked your home",
            submitButton: "Next",
        },
    },
    dashboard: {
        messageBoard: {
            title: (firstName) => (firstName ? `Welcome, ${firstName}!` : null),
            message: (phaseName) =>
                phaseName
                    ? `You're currently in the ${phaseName} Phase.`
                    : null,
        },
        home: {
            title: {
                text: "My Home",
            },
        },
        progress: {
            sectionHeaderText: "What To Expect",
            accordion: {
                text: {
                    previous: {
                        hide: "Hide previous stages",
                        show: "Show previous stages",
                    },
                    upcoming: {
                        hide: "Hide upcoming stages",
                        show: "Show upcoming stages",
                    },
                },
            },
        },
    },
    checklistModal: {
        introBody1:
            "This checklist is a list of events or activities that you should be aware of throughout the homebuying process.",
        introBody2:
            "Your personal checklist is created and managed by you, while the M/I Homes checklist items must be completed to move forward with the process of building and purchasing your home.",
        addTaskButtonText: "Add Checklist Item",
        upcomingText: "Upcoming",
        completedText: "Completed",
        noDueDateText: "No Due Date",
        markCompleteText: "Mark Complete",
        miTasksAccordion: {
            title: "M/I Homes Checklist",
        },
        personalTasksAccordion: {
            title: "Your Personal Checklist",
        },
        taskControls: {
            editText: "Edit",
            removeText: "Remove",
        },
        personalTaskForm: {
            errors: {
                title: "Checklist name is a required field.",
                fieldError: "There is an error with this field.",
            },
            checklistNameText: "Checklist Name",
            namePlaceholderText: "Enter your checklist name",
            dueDateText: "Due Date",
            dueDatePlaceholderText: "Select date",
            descriptionText: "Description",
            descriptionPlaceholderText:
                "Enter a description or notes about this task",
            cancelText: "Cancel",
            saveText: "Save",
        },
    },
    feedbackModal: {
        successTitle: "Thank you for your feedback!",
        successBody: "Your submission has been received.",
    },
    welcome: {
        buttonText: {
            next: "Next",
            done: "Done",
        },
        desktop: {
            screen1: {
                mainText: "Install this application to your desktop.",
                subText:
                    "When prompted, click allow on the popup to install the application to your desktop.",
            },
            screen2: {
                mainText:
                    "You can also add the application to your mobile device",
                subText:
                    "To do this, open the MyMIHome site in your mobile web browser, click install, add to your homescreen.",
            },
            screen3: {
                mainText: "Get the updates you want when you want them.",
                subText:
                    "How would you like to be notified about build progress, photo uploads and upcoming tasks?",
            },
            screen4: {
                mainText: "Let's check in on your new home.",
                subText:
                    "Welcome to MyMIHome, your personalized portal for following the progress of your home.",
            },
        },
        mobile: {
            ios: {
                screen1: {
                    mainText: "You can install this app on iOS",
                    subText: 'Tap share and select "Add to Home Screen".',
                },
            },
            android: {
                screen1: {
                    mainText: "You can install this app on Android",
                    subText:
                        'Tap "more (...)" and select "Add to Home Screen".',
                },
            },
            common: {
                screen1: {
                    mainText: "Install this application to your mobile.",
                    subText:
                        "Open the web browser, click install, add to your homescreen.",
                },
                screen2: {
                    mainText: "Get the updates you want when you want them.",
                    subText:
                        "How would you like to be notified about build progress, photo uploads and upcoming tasks?",
                },
                screen3: {
                    mainText: "Let's check in on your new home.",
                    subText:
                        "Welcome to MyMIHome, your personalized portal for following the progress of your home.",
                },
            },
        },
    },
    photogallery: {
        viewAllLink: "View All",
        noPhotos: "No Photos Yet",
    },
    warranty: {
        errorMessages: {
            homeNotClosed:
                "You cannot submit a service request until your home has closed and transitioned to Customer Care. Please contact your Construction Manager for any issues or concerns you have.",
            unableToGetCategories:
                "We are currently experiencing issues. Please try again later.",
        },
        details: {
            header: " Service Request",
            commonCategoriesHeader: "Common Categories",
            moreCategoriesHeader: "More Categories",
            viewMoreText: "View More",
            viewLessText: "View Less",
            cancelText: "Cancel",
            desktopDraftText: "Save as Draft",
            mobileDraftText: "Save Draft",
            reviewText: "Review",
            mobileReviewText: "Review My Information",
            desktopRequiredFieldsText:
                "All fields are required unless otherwise specified.",
            mobileRequiredFieldsText: "All fields are required",
            nextText: "Next",
            subcategoryText: "Please select a Subcategory",
            issueText: "Please choose an Issue",
            placeholder: "Details and Comments about your service request",
            subcategoryLabelText: "Subcategory",
            issueLabelText: "Issue",
            removePhotoText: "Remove Photos",
            addPhotoText: "Add",
            optional: "(Optional)",
            detailsError:
                "You are missing a few fields that need to be filled in.",
            saveDisclaimerText:
                "*Service Requests are only saved on this device.",
        },
        claimItem: {
            editText: "Edit",
            serviceItemText: "Your Service Item",
        },
        draftModal: {
            headerText: "Submit a Service Request",
            contentText:
                "You have items that didn’t get submitted. Do you want to finish submitting your most recent service request?",
            startNewClaimText: "Start a new Service Request",
            continueText: "Pick up where I left off",
        },
        review: {
            reviewHeaderText: "Review Your Service Request",
            submitText: "Submit My Service Request",
            backText: "Back",
        },
        cancelModal: {
            headerText: "Are you sure you want to cancel your service request?",
            contentText: "Your service request will not be saved.",
            noText: "No",
            confirmText: "Yes, Cancel",
        },
    },
};

export default Constants;
