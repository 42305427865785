import React, { useState, useEffect } from 'react';
import Utils from 'utilities/Utils';
import Slider from 'react-slick';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { isMobile, isTablet } from 'react-device-detect';
import {
    createSetPhotoModalPageNumberAction,
    createSetPhotoModalCaptionAction,
} from 'redux/actions/PhotoModal/PhotoModalActions';

const PhotoSlider = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        customSlider,
        thumbSlider,
        settings,
        images,
        currentPhotoIndex,
        thumbs,
        setHideThumbs,
        hideThumbs,
    } = props;

    const [isSwiping, setIsSwiping] = useState(false);


    const touchMe = (e) => {
        if (!isSwiping && !e.target.classList.contains('photo-action')) {
            setHideThumbs(!hideThumbs);
        }
    }

    const ts = () => {
        setIsSwiping(false);
    };

    const tm = () => {
        setIsSwiping(true);
    };

    useEffect(() => {
        window.addEventListener('touchstart', ts);
        window.addEventListener('touchmove', tm);
        window.addEventListener('touchend', touchMe);
        return () => {
            window.removeEventListener('touchend', touchMe)
            window.removeEventListener('touchstart', ts);
            window.removeEventListener('touchmove', tm);
        };
    }, [hideThumbs, touchMe]);

    useEffect(() => {
        if (customSlider && customSlider.current) {
            setTimeout(() => {
                customSlider.current && customSlider.current.innerSlider.list.setAttribute('tabindex', 0);
                customSlider.current && customSlider.current.innerSlider.list.focus();
            }, 1);
        }
    }, [customSlider]);

    return (
        <>
            <div className={`photoslider ${hideThumbs && 'photoslider--hide-thumbs'} photoslider--${isMobile ? 'mobile' : 'desktop'}`} data-testid="photoslider-container">
                <Slider
                    {...settings}
                    accessibility
                    asNavFor={thumbSlider && thumbSlider.current}
                    initialSlide={currentPhotoIndex}
                    ref={customSlider}
                    adaptiveHeight={false}
                    variableWidth={false}
                    onInit={(e) => {
                        navigate(`/${location.pathname.indexOf('/photos') >= 0 ? 'photos' : 'dashboard'}/${images && images[currentPhotoIndex] && Utils.hashCode(images[currentPhotoIndex].photoLink)}`)
                    }}
                    afterChange={(currentIndex) => {
                        customSlider.current && customSlider.current.innerSlider.list.setAttribute('tabindex', 0);
                        customSlider.current && customSlider.current.innerSlider.list.focus();
                        dispatch(createSetPhotoModalPageNumberAction(currentIndex + 1));
                        dispatch(createSetPhotoModalCaptionAction(images && images[currentIndex] && images[currentIndex].caption ? images[currentIndex].caption : ''));
                        navigate(`/${location.pathname.indexOf('/photos') >= 0 ? 'photos' : 'dashboard'}/${images && images[currentPhotoIndex] && Utils.hashCode(images[currentIndex].photoLink)}`)
                    }}
                >
                    {images &&
                        images.map((image) => (
                            <div
                                className="photo-details-wrapper"
                                data-testid="photo-details-wrapper"
                                key={image.currentPhotoIndex}
                            >
                                <img
                                    srcSet={`${Utils.fixImageUrl(
                                        image.photoLink
                                    )}`}
                                    alt={image.caption}
                                    data-caption={image.caption}
                                    sizes="(min-width: 764px) 977px, 100vw"
                                />

                            </div>
                        ))}
                </Slider>
            </div>
            {thumbs && isMobile && (
                <div className={`thumbnav ${isMobile && !isTablet ? 'thumbnav--mobile' : 'thumbnav--desktop'} ${hideThumbs && 'thumbnav--hide'}`} key="thumbs">
                    {thumbs && isMobile && (
                        <Slider
                            {...{
                                ...settings,
                                asNavFor: customSlider.current,
                                slidesToShow: Math.min(window.innerWidth, 1200) / 70,
                                freeMode: true,
                                centerMode: true,
                            }}
                            accessibility
                            initialSlide={currentPhotoIndex}
                            ref={thumbSlider}
                        >
                            {images &&
                                images.map((image, index) => (
                                    <button type="button"
                                        className="photo-details-wrapper"
                                        key={`small-${image.currentPhotoIndex}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            customSlider.current.slickGoTo(index);
                                            return false;
                                        }}
                                    >
                                        <img
                                            srcSet={`${Utils.fixImageUrl(
                                                image.photoLink
                                            )}`}
                                            alt={image.caption}
                                            data-caption={image.caption}
                                            sizes="(min-width: 764px) 977px, 100vw"
                                        />

                                    </button>
                                ))}
                        </Slider>
                    )}
                </div>
            )}
        </>
    );
};

export default PhotoSlider;
