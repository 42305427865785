import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Input, Icon, FlexibleLink } from 'ComponentLibrary';
import Constants from 'Constants';
import { useDispatch, useSelector } from 'react-redux';
import { IconTypes } from 'components/LibraryElements/Icon/Icon';
import {
    createAddTaskToBeCompletedAction,
    createRemoveTaskFromToBeCompletedAction,
    createAddTaskToBeUncompletedAction,
    createRemoveTaskFromToBeUncompletedAction,
    createRemovePersonalTaskAction,
    createOpenEditTaskFormAction,
} from 'redux/actions/Tasks/TasksActions';
import { createCloseChecklistModalAction } from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import Utils from 'utilities/Utils';
import { useParams, useLocation, Link } from 'react-router-dom';
import { useIsAdmin } from 'utilities/HooksAndSelectors';
import { Event } from 'components/Analytics';
import PersonalTaskControls from './PersonalTaskControls';

const ChecklistSingleTask = ({ task, sectionLabel, isPersonal, showAdminInfo = false }) => {
    const {
        description,
        title,
        taskId,
        dueDate,
        isUserComplete,
        isSystemComplete,
        links,
        occursText,
        dueDateStatus,
    } = task;


    const dispatch = useDispatch();
    const isAdmin = useIsAdmin();
    const location = useLocation();
    let dateString = '';
    if (dueDate) {
        const dateHalf = dueDate.split('T')[0];
        const datePieces = dateHalf.split('-');
        const d = new Date(dueDate);
        //d.setUTCMonth(datePieces[1] - 1);
        dateString = `${Utils.day(d)}, ${Utils.month(
            datePieces[1] - 1,
            false
        )} ${parseInt(datePieces[2], 10)}, ${datePieces[0]}`;
    }
    const [complete, setComplete] = useState(
        isSystemComplete || isUserComplete
    );
    const userState = useSelector((state) => state.userState);
    const taskState = useSelector((state) => state.taskState);
    const { profileAndSettings } = userState;
    const { taskIdsToComplete, taskFormData, showEditTaskForm } = taskState;
    let { focusedTask } = profileAndSettings;
    const urlParams = useParams();
    // override focused task with url param
    const fieldRef = React.useRef(null);
    if (urlParams.taskId) {
        focusedTask = parseInt(urlParams.taskId, 10);
    }
    if (location.pathname.indexOf('/checklist/') >= 0) {
        focusedTask = parseInt(location.pathname.split('/checklist/')[1], 10);
    }

    const openVal = focusedTask && focusedTask === taskId;
    const [expanded, setExpanded] = useState(openVal);

    const isTaskBeingEdited = taskFormData
        ? taskFormData.taskId === taskId
        : false;

    const isTaskAddedToComplete = taskIdsToComplete.indexOf(taskId) >= 0;

    React.useEffect(() => {
        if (fieldRef.current) {
            if (isPersonal) {
                setTimeout(() => {
                    //need to check again due to specific case when toggled task is deeplinked from dropdown
                    return fieldRef.current ? fieldRef.current.scrollIntoView() : '';
                }, 500);
            } else {
                fieldRef.current.scrollIntoView();
            }
        }
    }, [isPersonal]);

    const handleRemoveTask = () => {
        dispatch(createRemovePersonalTaskAction(taskId));
    };

    const handleEditTask = () => {
        dispatch(
            createOpenEditTaskFormAction({
                description,
                title,
                dueDate,
                taskId,
            })
        );
    };

    const buildToggleText = () => {
        if (isSystemComplete) {
            return (
                <p className="single-task__occur__phase system-completed">
                    {Constants.checklistDropdown.systemCompleted}
                </p>
            );
        }
        if (isPersonal && !dateString) {
            return (
                <p className="single-task__due-date">
                    {Constants.checklistModal.noDueDateText}
                </p>
            );
        }
        return dateString !== '' ? (
            <p
                className={`single-task__due-date
                ${!complete ? Utils.slugify(dueDateStatus) : ''}`}
            >
                Due: {dateString}
            </p>
        ) : (
            <p className="single-task__occur__phase">
                {Constants.checklistDropdown.phaseStarter} {occursText}
            </p>
        );
    };

    return (
        <Accordion
            defaultActiveKey={expanded || openVal ? '0' : '1'}
            as="ul"
            ref={openVal ? fieldRef : null}
            className={`top-level-task-category-list ${isTaskBeingEdited ? 'hide' : ''
                }`}
            data-testid="checklist-single-task-accordion"
        >
            <Card
                as="li"
                className={`single-task single-task--modal ${expanded && 'single-task--expanded'
                    }`}
            >
                <Input
                    id={`checklist-task-${sectionLabel}-${taskId}`}
                    testId={`checklist-task-${taskId}`}
                    type="checkbox"
                    checked={complete}
                    disabled={isAdmin}
                    isSystemComplete
                    name="checklist-task"
                    label={Constants.checklistModal.markCompleteText}
                    extraClasses={`input-wrap--task ${complete && 'input-wrap--task-complete'
                        }`}
                    extraLabelTextClasses="visuallyhidden"
                    extraCheckboxClasses={`checkbox--task ${complete && 'checkbox--task-complete'
                        }`}
                    extraFieldWrapClasses="field-wrap--task"
                    extraCheckboxIndicatorClasses={`checkbox__indicator--task ${complete && 'checkbox__indicator--task-complete'
                        }`}
                    extraInputClasses={`input--task ${complete && !isSystemComplete && 'input--task-complete'
                        }
                    ${complete &&
                        isSystemComplete &&
                        'input--task-system-complete'
                        }
                    `}
                    animated
                    onChange={(e) => {
                        // if it is complete in the backend
                        if (!isSystemComplete) {
                            if (isUserComplete) {
                                if (complete) {
                                    Event('tasks', 'uncomplete');
                                    dispatch(createAddTaskToBeUncompletedAction(taskId));
                                } else {
                                    Event('tasks', 'complete');
                                    dispatch(
                                        createRemoveTaskFromToBeUncompletedAction(taskId)
                                    );
                                }
                            } else if (complete) {
                                Event('tasks', 'uncomplete');
                                dispatch(createRemoveTaskFromToBeCompletedAction(taskId));
                            } else {
                                Event('tasks', 'complete');
                                dispatch(createAddTaskToBeCompletedAction(taskId));
                            }
                            setComplete(e.currentTarget.checked);
                        }
                    }}
                />
                <Accordion.Button
                    as={Button}
                    variant="link"
                    eventKey="0"
                    className="single-task__up-content"
                    onClick={(e) => {
                        setExpanded(!expanded);
                    }}
                >
                    <h2 className="single-task__title">{title}</h2>
                    {buildToggleText()}
                    <div
                        className={`single-task__icon ${expanded && 'single-task__icon--expanded'
                            }`}
                    >
                        <Icon
                            icon="skinnyarrow"
                            color={expanded ? 'white' : 'aqua'}
                            circle={expanded ? 'boston-blue' : 'neutral-light'}
                        />
                    </div>
                </Accordion.Button>
                <Accordion.Collapse eventKey="0" className="single-task__body">
                    <>
                        <p className="single-task__body-text">{description}</p>
                        <div className="single-task__links">
                            {links &&
                                links.map((link, index) => {
                                    let { text, url, target } = link;
                                    const urlToUse = url === '[EXTERNAL]/designcenter/confirmedselections' ? '/design-selections' : url
                                    const isAcuity = urlToUse === '#first-design-scheduling';
                                    if (!text || text === '') {
                                        return null;
                                    }
                                    const isInternal =
                                        target !== '_blank' &&
                                        urlToUse.indexOf('/') === 0 &&
                                        urlToUse.indexOf('//:') < 0 &&
                                        urlToUse.indexOf('[EXTERNAL]') < 0;

                                    const content = (
                                        <>
                                            {text}
                                            <Icon
                                                icon={IconTypes.DoubleArrow}
                                            />
                                        </>
                                    );

                                    return isInternal ? (
                                        <FlexibleLink
                                            isInternal={isInternal}
                                            key={`task-link-${index}`}
                                            classList="single-task__link"
                                            testId="single-task__link"
                                            handleClick={(e) => {
                                                dispatch(
                                                    createCloseChecklistModalAction()
                                                );
                                            }}
                                            linkDetails={
                                                {
                                                    ...link,
                                                    url: urlToUse,
                                                }
                                            }
                                        >
                                            {content}
                                        </FlexibleLink>
                                    ) : (
                                        <FlexibleLink
                                            isInternal={isInternal}
                                            key={`task-link-${index}`}
                                            classList={`single-task__link ${isAcuity && isAdmin ? 'disabled' : ''}`}
                                            testId="single-task__link"
                                            linkDetails={
                                                {
                                                    ...link,
                                                    url: urlToUse.replace('[EXTERNAL]', ''),
                                                }
                                            }
                                            rel="nofollow noopener noreferrer"
                                        >
                                            {content}
                                        </FlexibleLink>
                                    );
                                })}
                        </div>
                        {isPersonal && (
                            <PersonalTaskControls
                                handleRemoveTask={handleRemoveTask}
                                handleEditTask={handleEditTask}
                                disableAllControls={isTaskAddedToComplete}
                                disableEdit={showEditTaskForm}
                            />
                        )}
                        {isAdmin && showAdminInfo && (
                            <div>
                                <p>IsApplicable: {task.isApplicableScript}</p>
                                <p>DueDate: {task.dueDateScript}</p>
                            </div>
                        )}
                    </>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};
ChecklistSingleTask.defaultProps = {
    sectionLabel: '',
};
ChecklistSingleTask.propTypes = {
    sectionLabel: PropTypes.string,
    task: PropTypes.object.isRequired,
};

export default ChecklistSingleTask;
