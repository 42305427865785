/* eslint-disable import/prefer-default-export */
import { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Constants from 'Constants';

import {
    createGetLotConfigurationAction,
    createGetProgressContentAction,
} from 'redux/actions/ProgressContent/ProgressContentActions';

export const useHaveUser = () => {
    const userState = useSelector((state) => state.userState);
    return !!(userState && userState.userDetails && userState.userDetails.user);
};

export const useHaveFatalError = () => {
    const userState = useSelector((state) => state.userState);
    return !!(
        userState &&
        userState.userDetails &&
        userState.userDetails.errors.isFatal
    );
};

export const useOutsideAlerter = (ref, callback) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event.target);
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

export const useWindowSize = (orientationToo) => {
    const [size, setSize] = useState([0, 0]);
    useEffect(() => {
        function updateSize() {
            setSize([
                window.innerWidth,
                window.innerHeight,
                window.visualViewport ? window.innerWidth * window.visualViewport.scale : 0,
                window.visualViewport ? window.innerHeight * window.visualViewport.scale : 0,
            ]);
        }
        window.addEventListener('resize', updateSize);
        if (orientationToo) {
            window.addEventListener('orientationchange', updateSize);
        }
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        window.innerWidth,
        window.innerHeight,
        orientationToo,
        window.orientation,
    ]);
    return size;
};


export const useHasCurrentLotContent = () => {
    const userState = useSelector((state) => state.userState);
    const { profileAndSettings, progressContent } = userState;
    const { activeLotId } = profileAndSettings;
    const { lots } = progressContent;
    if (!activeLotId) {
        return false;
    }
    const lot = lots[activeLotId.toString()];
    if (lot && lot.contentRequested) {
        return 'requested';
    }
    if (lot !== null && lot !== undefined) {
        const keys = Object.keys(lot);
        if (keys.length === 1 && keys[0] === 'configuration') {
            // we only have configuration, not content
            return false;
        }
    }
    const ret =
        lot !== null &&
        lot !== undefined &&
        lot.progress &&
        lot.progress[0].contentLoaded;
    return ret;
};

export const useHasTasks = () => {
    const taskState = useSelector((state) => state.taskState);
    return taskState.tasks;
};

export const useHasPersonalTasks = () => {
    const taskState = useSelector((state) => state.taskState);
    return taskState.personalTasks;
};

export const useHasNotifications = () => {
    const notificationsState = useSelector((state) => state.notificationsState);
    return notificationsState.notifications;
};

export const useHasDocuments = () => {
    const documentsState = useSelector((state) => state.documentsState);
    const { documents } = documentsState;
    const [hasDocuments, setHasDocuments] = useState(false);
    useEffect(() => {
        if (documents) {
            setHasDocuments(true);
        } else {
            setHasDocuments(false);
        }
    }, [documentsState, documents]);
    return hasDocuments;
};

export const useHasDeposits = () => {
    const depositsState = useSelector((state) => state.depositsState);
    return depositsState.deposits;
};

export const useHasDesignSelections = () => {
    const designSelectionsState = useSelector(
        (state) => state.designSelectionsState
    );
    return designSelectionsState.selections;
};

export const useHasCurrentLotConfiguration = () => {
    const userState = useSelector((state) => state.userState);
    const { profileAndSettings, progressContent } = userState;
    const { activeLotId } = profileAndSettings;
    const { lots } = progressContent;
    const lot = lots[activeLotId];
    if (lot && lot.configuration && lot.configuration === 'requested') {
        return 'requested';
    }
    return lot !== null && lot !== undefined && lot.configuration;
};

export const useGetTabContactData = (tabSlug) => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    if (!activeLotId) {
        return null;
    }
    const lot = lots[activeLotId.toString()];
    if (!lot || !lot.progress) {
        return null;
    }
    const filtered = lot.progress.filter((prog) => prog && prog.stageKey === tabSlug);
    const singlePhase = filtered[0];
    const contact = singlePhase ? singlePhase.contact : null;
    return contact;
};

export const useHaveWeRequestedContent = () => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;

    let haveWeRequested = false;
    if (activeLotId && lots[activeLotId.toString()]) {
        if (lots[activeLotId.toString()].contentRequested) {
            haveWeRequested = true;
        }
    }
    return haveWeRequested;
};
export const useGetTabDataForCurrentLot = (doDispatch: false) => {
    const userState = useSelector((state) => state.userState);
    const dispatch = useDispatch();
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const initialValue =
        activeLotId &&
            lots[activeLotId.toString()] &&
            lots[activeLotId.toString()].progress
            ? lots[activeLotId.toString()].progress
            : null;
    let haveWeRequested = false;
    if (activeLotId && lots[activeLotId.toString()]) {
        if (lots[activeLotId.toString()].contentRequested) {
            haveWeRequested = true;
        }
    }
    const [data, setData] = useState(initialValue);

    useEffect(() => {
        const lot = activeLotId ? lots[activeLotId.toString()] : null;
        if (lot && lot.progress) {
            setData(lot.progress);
        } else if (doDispatch && !haveWeRequested) {
            const action = createGetProgressContentAction({
                lotId: activeLotId,
            });
            dispatch(action);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progressContent, profileAndSettings, activeLotId, lots]);

    return data;
};

export const useGetConfigurationForCurrentLot = (doDispatch) => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const [data, setData] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (activeLotId && (!data || !data.features || !data.progress)) {
            const lot = lots[activeLotId.toString()];
            if (lot && lot.configuration) {
                if (lot.configuration !== 'requested') {
                    if (!data || !data.features || !data.progress) {
                        setData(lot.configuration);
                    }
                }
            } else if (doDispatch) {
                const action = createGetLotConfigurationAction({
                    lotId: activeLotId,
                });
                dispatch(action);
            }
        }
    }, [
        data,
        progressContent,
        doDispatch,
        dispatch,
        profileAndSettings,
        activeLotId,
        lots,
    ]);

    return data || {
        features: {
            showProgressDashboard: true,
            showMyHome: true,
            showWelcomeBanner: true,
        },
        progress: [],
    };
};

export const useIsAdmin = () => {
    const userState = useSelector((state) => state.userState);
    const { userDetails } = userState;
    const { roles } = userDetails;
    const [isAdmin, setIsAdmin] = useState('do-not-know-yet');
    useEffect(() => {
        const adminRoles =
            roles &&
            roles.filter(
                (role) => role.description === Constants.global.adminRole
            );
        if (adminRoles && adminRoles.length > 0) {
            setIsAdmin(true);
        } else {
            setIsAdmin(false);
        }
    }, [roles]);
    return isAdmin;
};

export const useHasOrientationData = () => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const initialValue =
        activeLotId &&
            lots[activeLotId.toString()] && lots[activeLotId.toString()].scheduledNHODateTime
            ? lots[activeLotId.toString()].scheduledNHODateTime
            : null;
    const [data, setData] = useState(initialValue);
    useEffect(() => {
        const lot = activeLotId ? lots[activeLotId.toString()] : null;
        if (lot && lot.scheduledNHODateTime) {
            setData(lot.scheduledNHODateTime);
        }
        if (lot && lot.progress) {
            const closing = lot.progress.filter((step) => step.stageKey === 'construction');
            if (closing && closing[0] && closing[0].status === 'Complete') {
                setData(null);
            }
        }
    }, [progressContent, profileAndSettings, activeLotId, lots]);

    return data;
};

export const useHasClosingData = () => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const initialValue =
        activeLotId &&
            lots[activeLotId.toString()] && lots[activeLotId.toString()].scheduledBuyerClosingDateTime
            ? lots[activeLotId.toString()].scheduledBuyerClosingDateTime
            : null;
    const [data, setData] = useState(initialValue);
    useEffect(() => {
        const lot = activeLotId ? lots[activeLotId.toString()] : null;
        if (lot && lot.scheduledBuyerClosingDateTime) {
            setData(lot.scheduledBuyerClosingDateTime);
        }
        if (lot && lot.progress) {
            const closing = lot.progress.filter((step) => step.stageKey === 'closing');
            if (closing && closing[0] && closing[0].status === 'Complete') {
                setData(null);
            }
        }
    }, [progressContent, profileAndSettings, activeLotId, lots]);

    return data;
};

export const useHaveAdminLot = () => {
    try {
        const userState = useSelector((state) => state.userState);
        const { profileAndSettings } = userState;
        const activeLotId = profileAndSettings.activeLotId || null;
        return activeLotId !== null;
    } catch (e) {
        return false;
    }
};

export const useCurrentLotId = () => {
    const userState = useSelector((state) => state.userState);
    const { profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;

    const [data, setData] = useState(activeLotId);

    useEffect(() => {
        setData(activeLotId);
    }, [activeLotId]);
    return data;
};

export const useCurrentPage = () => {
    const userState = useSelector((state) => state.userState.userDetails);
    const { currentPage: currentPageFromState } = userState;
    const [currentPage, setCurrentPage] = useState(currentPageFromState);
    useEffect(() => {
        const { currentPage: currentPageFromStateInEffect } = userState;
        setCurrentPage(currentPageFromStateInEffect);
    }, [currentPageFromState, userState]);
    return currentPage;
}

export const useGetPhotosForCurrentLot = () => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const [data, setData] = useState(null);

    useEffect(() => {
        if (activeLotId) {
            const lot = lots[activeLotId.toString()];
            if (lot && lot.photos) {
                setData(lot.photos);
            }
        }
        return () => { };
    }, [progressContent, profileAndSettings, activeLotId, lots]);

    return data;
};

export const useGetDocumentsDataForCurrentLot = () => {
    const userState = useSelector((state) => state.userState);
    const { progressContent, profileAndSettings } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;
    const { lots } = progressContent;
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!activeLotId) {
            return null;
        }
        const lot = lots[activeLotId.toString()];
        if (lot && lot.documentsPage) {
            setData(lot.documentsPage);
        }
    }, [progressContent, profileAndSettings, activeLotId, lots]);

    return data;
};

export const useAwaitTokenHasBeenRefreshed = () => {
    const userState = useSelector((state) => state.userState);
    const { userDetails } = userState;

    const [refreshCookie, setRefreshCookie] = useState(
        userDetails.refreshCookie
    );

    useEffect(() => {
        setRefreshCookie(userDetails.refreshCookie);
    }, [userDetails.refreshCookie]);
    return refreshCookie !== 'checking';
};

export const useGetCurrentLotSummary = (isAdmin = false) => {

    const userState = useSelector((state) => state.userState);
    const { profileAndSettings, userDetails, progressContent } = userState;
    const activeLotId = profileAndSettings.activeLotId || null;

    const { lotAssociations } = userDetails;
    if (isAdmin && progressContent.lots[activeLotId]) {
        return {
            address: progressContent.lots[activeLotId],
        };
    }
    const filteredLot = lotAssociations.filter(
        (lot) =>
            lot.lotId === activeLotId ||
            lot.lotId.toString() === activeLotId.toString()
    );
    const ret = filteredLot.length ? filteredLot[0].lotSummary : null;

    return ret;
};

export const useLocalStorage = (key, initialValue) => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
};

export const useLockBodyScroll = () => {
    useLayoutEffect(() => {
        // Get original body overflow
        const originalStyle = window.getComputedStyle(document.body).overflow;
        // Prevent scrolling on mount
        document.body.style.overflow = 'hidden';
        // Re-enable scrolling when component unmounts
        return () => (document.body.style.overflow = originalStyle);
    }, []); // Empty array ensures effect is only run on mount and unmount
};

export const useHasCategories = () => {
    const warrantyState = useSelector((state) => state.warrantyState);
    return warrantyState.warrantyDetails.categories;
};
