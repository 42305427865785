import React, { useState } from 'react'
import { Input, IconSizes } from 'ComponentLibrary'
import PropTypes from 'prop-types'

import { useSelector, useDispatch } from 'react-redux'
import { useIsAdmin } from 'utilities/HooksAndSelectors'
import PWAService from 'services/PWAService'
import { createHavePushSubscriptionAction } from 'redux/actions/Notifications/NotificationsActions'

const NotificationPreferencesForm = (props) => {
  const { uniqueId, modalOpen } = props
  const userState = useSelector((state) => state.userState)
  const isAdmin = useIsAdmin()

  const { userDetails } = userState
  const dispatch = useDispatch()
  const { notificationSettings, notificationSubscriptions } = userDetails
  const [data, setData] = useState(notificationSettings)
  const [databaseSubscription, setDatabaseSubscription] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const updateValue = (e) => {
    const oldData = { ...data }
    const newData = { ...oldData }
    newData[e.currentTarget.name] = e.currentTarget.checked
    newData.updateFlag = true
    props.onUpdate({
      ...newData,
      subscription: {
        pushSubscription: subscription,
        databaseSubscription,
      },
    })
    setData(newData)
  }

  const doesntSupportsPush = !('PushManager' in window)

  if (!data || !notificationSettings) {
    return null
  }

  const sub = PWAService.getPushNotificationSubscription()
  if (sub) {
    sub.then((res) => {
      if (modalOpen && subscription === null) {
        try {
          setSubscription(res)
          if (res && notificationSubscriptions) {
            let foundOne = false
            notificationSubscriptions.forEach((sub) => {
              if (res && res.endpoint === sub.subscriptionUrl) {
                updateValue({
                  currentTarget: { name: 'enablePush', checked: true },
                })
                dispatch(createHavePushSubscriptionAction())
                setDatabaseSubscription(sub)
                foundOne = true
              }
            })
            if (!foundOne) {
              setDatabaseSubscription(null)
            }
          }
        } catch (e) {}
      }
    })
  }

  return (
    <div
      className='notifications-preference-form'
      data-testid='notifications-preference-form'
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          return false
        }}
      >
        <fieldset>
          <Input
            disabled={isAdmin}
            type='checkbox'
            name='enableText'
            testId='text-notififications-enable'
            id={`enableText-${uniqueId}`}
            label='Text Messages'
            required={false}
            onChange={updateValue}
            tooltipIcon='Info'
            content='Receive updates via text message to the phone number on your account. To change this, edit your Profile in Account Settings.'
            tooltipSize={IconSizes.Tiny}
            tooltip
            checked={data.enableText}
            first
            extraClasses='input-wrap--checkbox-small-mobile-bottom-margin'
          />
          <Input
            disabled={isAdmin}
            type='checkbox'
            name='enableEmail'
            testId='email-notififications-enable'
            id={`enableEmail-${uniqueId}`}
            label='Email'
            required={false}
            onChange={updateValue}
            checked={data.enableEmail}
            tooltipIcon='Info'
            content='Receive updates via email to the email address on your account. To change this, edit your Profile in Account Settings.'
            tooltip
            tooltipSize={IconSizes.Tiny}
            extraClasses='input-wrap--checkbox-small-mobile-bottom-margin'
          />
          {!doesntSupportsPush && (
            <Input
              disabled={isAdmin}
              type='checkbox'
              id={`enablePush-${uniqueId}`}
              testId='push-notififications-enable'
              name='enablePush'
              label='Badge Notifications'
              required={false}
              onChange={updateValue}
              tooltipIcon='Info'
              tooltip
              tooltipSize={IconSizes.Tiny}
              checked={data.enablePush}
              content='Badge Notifications will be pushed to this device & browser only.  Note: If you would like to receive notifications on more than one device or browser, this setting will need to be enabled on that device or browser as well.'
              extraClasses='input-wrap--checkbox-small-mobile-bottom-margin'
              force={true}
            />
          )}
        </fieldset>
      </form>
    </div>
  )
}
NotificationPreferencesForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  uniqueId: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
}

export default NotificationPreferencesForm
