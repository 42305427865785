import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    useGetTabDataForCurrentLot,
    useHaveFatalError,
} from 'utilities/HooksAndSelectors';
import { handleFinancingExternalRedirectErrorAction } from 'redux/actions/ExternalRedirect/ExternalRedirectActions';


const ExternalRedirect = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.userState);
    const hasFatalError = useHaveFatalError();
    const { redirectUrl } = userState.profileAndSettings;
    const [haveReachedMinimumTime, setHaveReachedMinimumTime] = useState(false);
    const progressData = useGetTabDataForCurrentLot(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setHaveReachedMinimumTime(true);
        }, 3500);
        return () => clearTimeout(timer);
    }, []);
    // eslint-disable-next-line react/destructuring-assignment
    if (props.redirectUrl) {
        if (props.redirectUrl.indexOf('{$lotId}') >= 0) {
            if (
                userState &&
                userState.profileAndSettings &&
                userState.profileAndSettings.activeLotId
            ) {
                const redirect = props.redirectUrl.replace(
                    '{$lotId}',
                    userState.profileAndSettings.activeLotId
                );
                if (haveReachedMinimumTime) {
                    //history.replace(redirect);
                    window.location.assign(redirect);
                    return <Navigate to={redirect} />

                }
            }
        }
        if (
            props.redirectUrl.indexOf('{$financingLink}') >= 0 &&
            progressData
        ) {
            const designContent = progressData.filter(
                (tab) => tab.stageKey === 'financing'
            );

            const designTabContent =
                designContent && designContent[0] ? designContent[0] : null;
            if (designTabContent) {
                if (designTabContent.office === null && designTabContent.office !== undefined) {
                    if (!hasFatalError) {
                        dispatch(handleFinancingExternalRedirectErrorAction());
                    }
                }
            }
            if (designTabContent.office) {
                if (designTabContent.office.linkToPage === null && designTabContent.office.linkToPage !== undefined) {
                    if (!hasFatalError) {
                        dispatch(handleFinancingExternalRedirectErrorAction());
                    }
                }
            }
            if (
                designTabContent &&
                designTabContent.office &&
                designTabContent.office.linkToPage
            ) {
                window.location.assign(designTabContent.office.linkToPage);
            } else if (designTabContent && designTabContent.office && designTabContent.office.linkToPage === null) {

            }
        }
    }

    useEffect(() => {
        console.log('what are they john');
        console.dir(props);
        if (!props.redirectUrl) {
            if (!redirectUrl && props.actionCreator) {
                dispatch(props.actionCreator());
            } else {
                //window.location.assign(redirectUrl);
                navigate(redirectUrl)
                //return <Navigate to={redirectUrl} />
                //history.replace(redirectUrl);

            }
        }
    }, [dispatch, redirectUrl, props]);
    return null;
};

export default ExternalRedirect;
