import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

const sendPageView = (location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
};

const AnalyticsListener = ({ children, trackingCode }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // execute on location change
        sendPageView(location);
    }, [location]);

    useEffect(() => {
        ReactGA.initialize(trackingCode, {
            debug: process.env.NODE_ENV !== 'production',
        });
        sendPageView(location);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
};

AnalyticsListener.propTypes = {
    children: PropTypes.node.isRequired,
    trackingCode: PropTypes.string.isRequired,
};

export const Event = (category, action, label) => {
    ReactGA.event({
        category: category ? category.toLowerCase() : null,
        action: action ? action.toLowerCase() : null,
        label,
    });
};

export default AnalyticsListener;
