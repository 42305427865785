/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import './NavHeader.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
    UserBadge,
    ChecklistToggle,
    NotificationsToggle,
} from 'ComponentLibrary';
import logo from 'icons/logo-transparent.png';
import {
    useHaveUser,
    useHaveFatalError,
    useIsAdmin,
    useHaveAdminLot,
    useGetPhotosForCurrentLot,
} from 'utilities/HooksAndSelectors';
import { createGetTasksAction } from 'redux/actions/Tasks/TasksActions';
import { Container, Row } from 'react-bootstrap';
import routeConfig from 'routeConfig';

export default function NavHeader(props) {
    const { links } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const haveUser = useHaveUser();
    const hasFatalError = useHaveFatalError();
    const isEmptyHeader = hasFatalError || !(links || haveUser);
    const userState = useSelector((state) => state.userState);

    const { profileAndSettings } = userState;
    const { activeLotId } = profileAndSettings;
    const taskState = useSelector((state) => state.taskState);
    const { tasks } = taskState;

    const usesBottomNavigation = [
        '/home',
        '/checklist',
        '/dashboard',
        '/progress',
        '/photos',
        '/tasks',
        '/contacts',
        '/profile',
        '/warranty',
        '/warranty/submit',
        '/warranty/success',
        '/documents',
        '/division-dashboard',
        '/lot-dashboard',
        '/deposits',
        '/design-selections',
        '/designcenter/confirmedselections',
    ];
    const location = useLocation();
    const currentPath = location.pathname;

    let hide = false;
    let pageBusy = true;
    usesBottomNavigation.forEach((path) => {
        if (currentPath.indexOf(path) === 0) {
            hide = true;
        }
    });

    if (haveUser) {
        const { userDetails } = userState;
        const { busy } = userDetails;
        pageBusy = busy;
    }

    const isAdmin = useIsAdmin();
    const haveAdminLot = useHaveAdminLot();
    const photos = useGetPhotosForCurrentLot();

    const isDashboard =
        currentPath.indexOf('/dashboard') === 0 ||
        currentPath.indexOf('/contacts') === 0 ||
        currentPath.indexOf('/documents') === 0 ||
        currentPath.indexOf('/warranty') === 0 ||
        currentPath.indexOf('/photos') === 0 ||
        currentPath.indexOf('/division-dashboard') === 0 ||
        currentPath.indexOf('/lot-dashboard') === 0 ||
        currentPath.indexOf('/deposits') === 0 ||
        currentPath.indexOf('/design-selections') === 0 ||
        currentPath.indexOf('/designcenter/confirmedselections') === 0;

    const isContacts = currentPath.indexOf('/contacts') === 0;
    const isDocuments = currentPath.indexOf('/documents') === 0;
    const isDeposits = currentPath.indexOf('/deposits') === 0;
    const isDesignSelections = currentPath.indexOf('/design-selections') === 0 || currentPath.indexOf('/designcenter/confirmedselections') === 0;
    const isInvite = currentPath.indexOf('/invite') === 0;
    const hasMultiplePhotos = photos && photos.length > 1;

    if ((isContacts || isDocuments || isDeposits || isDesignSelections) && haveUser) {
        pageBusy = false;
    }
    const islotAssociation =
        currentPath.indexOf('/lot-association') === 0 ||
        currentPath.indexOf('/find-lot') === 0;
    const isLogin = currentPath.indexOf('/login') === 0 || currentPath === '/';
    const isSuccess = currentPath.indexOf('/success') === 0;
    const isFindLot = currentPath.indexOf('/find-lot') === 0;

    useEffect(() => {
        // maybe instead of checking for active lot, we check busy, or add a falg?
        if (activeLotId && tasks === '' && tasks !== 'requested' && activeLotId.toString().match(/\d{12}/g)) {
            if (!isFindLot) {
                dispatch(createGetTasksAction());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeLotId, tasks, isFindLot]);

    let haveMatch = false;
    // eslint-disable-next-line array-callback-return
    routeConfig.map((route) => {
        if (route.path.indexOf(location.pathname) === 0) {
            haveMatch = true;
        }
    });
    if (location.pathname.indexOf('/dashboard') === 0) {
        haveMatch = true;
    }

    return (
        <header
            className={`mi-navigation-header ${hide && 'mi-navigation-header--hide-mobile'
                }`}
            data-testid="mi-navigation-header"
        >
            <Container style={{ position: 'relative' }}>
                <Row className="justify-content-left--desktop">
                    <a
                        href="/login"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/dashboard');
                            return false;
                        }}
                        data-testid="header-logo-anchor"
                        className={`header-logo ${isEmptyHeader || !haveMatch ? 'centered' : ''
                            } ${isDashboard ? '' : 'centered'}`}
                    >
                        <img
                            className="header-logo__img"
                            src={logo}
                            data-testid="header-logo"
                            alt="M/I Homes - Buyer Portal"
                            width="141"
                            height="120"
                        />
                    </a>
                    {haveMatch && !isLogin && !isFindLot && !isInvite && !islotAssociation && !isSuccess && links &&
                        links.map((link, index) => {
                            const { path, name } = link;
                            if (name === 'Photos' && !hasMultiplePhotos) {
                                return null;
                            }
                            return (
                                <NavLink
                                    to={path}
                                    activeClassName="selected-route"
                                    key={`navigation-link-${index}`}
                                    data-testid="navigation-header-link"
                                    className="navigation-header-link"
                                >
                                    <span className="navigation-header-link__text">
                                        {name}
                                    </span>
                                </NavLink>
                            );
                        })}
                    {haveUser &&
                        !hasFatalError &&
                        (!isAdmin || (isAdmin && haveAdminLot && !islotAssociation)) &&
                        !isLogin &&
                        !islotAssociation &&
                        !isSuccess &&
                        !pageBusy && (
                            <div className="navigation-header-checklist-container">
                                <ChecklistToggle />
                            </div>
                        )}
                    {haveUser &&
                        !hasFatalError &&
                        (!isAdmin || (isAdmin && haveAdminLot && !islotAssociation)) &&
                        !isLogin &&
                        !islotAssociation &&
                        !isSuccess &&
                        !pageBusy && (
                            <div className="navigation-header-notifications-container">
                                <NotificationsToggle />
                            </div>
                        )}
                    {haveUser &&
                        !hasFatalError &&
                        (!isAdmin || (isAdmin && haveAdminLot && !islotAssociation)) &&
                        !islotAssociation &&
                        !isSuccess &&
                        !pageBusy && (
                            <div className="navigation-header-user-container">
                                <UserBadge
                                    className="navigation-header-user"
                                    clickable
                                    size="extra-small"
                                    noBorder
                                />
                            </div>
                        )}
                </Row>
            </Container>
        </header>
    );
}

NavHeader.defaultProps = {
    links: null,
};

NavHeader.propTypes = {
    links: PropTypes.array,
};
