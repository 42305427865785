import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useNavigate, useParams, useLocation, redirect } from 'react-router-dom';
import { CustomSelect } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import { isMobile } from 'react-device-detect';
import { useWindowSize } from 'utilities/HooksAndSelectors';
import Tab from './Tab';
import TabContent from './TabContent';
import ConstructionTabContent from './ConstructionTabContent';
import WarrantyTabContent from './WarrantyTabContent';
import FinancingTabContent from './FinancingTabContent';
import ClosingTabContent from './ClosingTabContent';
import DesignTabContent from './DesignTabContent';
import PreContructionTabContent from './PreContructionTabContent';
import './Tabs.scss';

const getTabContent = (tabData, active) => {
  const { stageKey } = tabData;
  switch (stageKey) {
    case 'construction':
      return (
        <ConstructionTabContent
          key="construction"
          active={active}
          tab={tabData}
        />
      );
    case 'warranty':
      return (
        <WarrantyTabContent
          key="warranty"
          active={active}
          tab={tabData}
        />
      );
    case 'financing':
      return (
        <FinancingTabContent
          key="financing"
          active={active}
          tab={tabData}
        />
      );
    case 'closing':
      return (
        <ClosingTabContent
          key="closing"
          active={active}
          tab={tabData}
        />
      );
    case 'design':
      return (
        <DesignTabContent key="design" active={active} tab={tabData} />
      );
    case 'preconstruction':
      return (
        <PreContructionTabContent
          key="preconstruction"
          active={active}
          tab={tabData}
        />
      );
    default:
      return <TabContent key="generic" active={active} tab={tabData} />;
  }
};

const Tabs = (props) => {
  const { tab, constructionStepId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { tabs, testId, bg, mobileOnly, desktopOnly } = props;

  let initialTabIndex = null;
  let foundFirst = false;
  tabs.forEach((singleTab, tabIndex) => {
    if (singleTab.title === undefined) {
      return;
    }

    if (Utils.slugify(singleTab.title) === tab) {
      initialTabIndex = tabIndex;
    }
    if (
      (!tab || tab === undefined) &&
      !foundFirst &&
      Utils.slugify(singleTab.status) === 'started'
    ) {
      foundFirst = true;
      initialTabIndex = tabIndex;
    }
  });
  const [activeIndex, setActiveIndex] = useState(initialTabIndex);
  if (activeIndex === null && initialTabIndex !== null) {
    //do not do anything
  } else if (
    tabs.length > 0 &&
    tabs[0].title &&
    activeIndex === null &&
    initialTabIndex === null &&
    (tab === '' || tab === undefined)
  ) {
    if (!foundFirst) {
      setActiveIndex(0);
    }
  }

  useEffect(() => {
    if (tab === undefined && mobileOnly) {
      if (initialTabIndex !== null) {
        setActiveIndex(initialTabIndex);
      } else {
        setActiveIndex(0);
      }
    }
  }, [
    activeIndex,
    tabs,
    initialTabIndex,
    mobileOnly,
    location.pathname,
    tab,
  ]);
  // eslint-disable-next-line no-unused-vars
  const [width, height, visualWidth] = useWindowSize();
  if (tab && tab.indexOf('checklist') < 0 && isMobile && (width < 764 || (visualWidth < 764 && visualWidth > 0)) && !mobileOnly) {
    const nums = new RegExp(/[0-9]+/);
    if (!nums.test(tab)) {
      return redirect(`/progress/${tab}${constructionStepId ? `/${constructionStepId}` : ''}`);
    }
  }

  let tabRenders = [];
  tabRenders = tabs.map((t, index) => {
    const active = activeIndex === index;
    const first = index === 0;
    const last = index === tabs.length - 1;
    const { title, status } = t;
    const propsToPass = {
      title,
      active,
      testId,
      first,
      last,
      index,
      status: Utils.slugify(status),
      slug: t.stageKey,
      key: `tab - ${index} -${mobileOnly} -${desktopOnly} `,
      onClick: (e) => {
        const tabIndex = parseInt(
          e.currentTarget.getAttribute('data-index'),
          10
        );
        setActiveIndex(tabIndex);
        navigate(`/dashboard/${Utils.slugify(tabs[tabIndex].title)}`);
      },
    };
    return <Tab {...propsToPass} />;
  });
  return (
    <div>
      {!mobileOnly && (
        <nav className="tabs__nav" data-testid="tabs__nav">
          <ol role="tablist" className="tabs__nav-list">
            {tabRenders}
          </ol>
        </nav>
      )}

      {!desktopOnly && tabs && tabs.length > 0 && tabs[0].title && (
        <div className="tabs__mobile-nav bg-neutral-light" data-testid="tabs__mobile-nav">
          <CustomSelect
            id="progress-tabs-select"
            bg={bg}
            status={
              tabs[activeIndex] && tabs[activeIndex].status
                ? tabs[activeIndex].status
                : null
            }
            options={tabs}
            initialValue={activeIndex}
            onChange={(val) => {
              setActiveIndex(val);
              navigate(
                `/progress/${Utils.slugify(tabs[val].title)}`
              );
            }}
          />
        </div>
      )}

      <div className="tabs__content no-gutters">
        {tabs.map((contentTab, index) =>
          getTabContent(contentTab, index === activeIndex)
        )}
      </div>
    </div>
  );
};

Tabs.defaultProps = {
  tabs: [],
  testId: null,
  bg: null,
  mobileOnly: false,
  desktopOnly: false,
};

Tabs.propTypes = {
  tabs: PropTypes.array,

  testId: PropTypes.string,
  bg: PropTypes.string,
  mobileOnly: PropTypes.bool,
  desktopOnly: PropTypes.bool,
};

export default Tabs;
