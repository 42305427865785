import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/LibraryElements/Icon';
import './BackButton.scss';

const BackButton = (props) => {
    const navigate = useNavigate();
    return (
        <button
            onClick={() => navigate(-1)}
            style={{
                background: 'transparent',
                zIndex: '2',
                transform: 'translateY(-50%)',
                outline: 'none',
                border: '0',
                width: '40px',
                padding: '0',
                height: '40px',
                position: 'absolute',
                left: '0',
                top: '50%',
                textIndent: '-999em',
            }}
            type="button"
            data-testid="back-button"
            className="back-button"
        >
            <div style={{ position: 'relative' }}>
                <Icon
                    icon="skinnyarrow"
                    size={2}
                    color="neutral-dark"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '40px',
                        width: '100%',
                        position: 'absolute',
                        transform: 'translate(-50%, -50%) rotate(90deg)',
                        top: '50%',
                        left: '50%',
                    }}
                />
            </div>
        </button>
    );
};


export default BackButton;
