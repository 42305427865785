import React from 'react';
import { Provider } from 'react-redux';
import store from 'redux/configureStore';
import BuyerPortalRouter from './BuyerPortalRouter';
import './App.scss';

// This is the main entry point into the M/I Homes Customer Buyer Portal.

const BuyerPortalApp = () => (
    <Provider store={store}>
        <BuyerPortalRouter />
    </Provider>
);

export default BuyerPortalApp;
