import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createloginAction, updateUserDetailsAction } from 'redux/actions/Login/LoginActions';
import { Container, Row, Col } from 'react-bootstrap';
import CookieService from 'services/CookieService';
import { Title } from 'ComponentLibrary';
import LoginForm from './ContentComponents/LoginForm';
import Constants from '../../Constants';
import {
    createFetchLotToolTipContentAction,
} from 'redux/actions/LotAssociation/LotAssociationActions';

const Login = () => {
    const dispatch = useDispatch();
    const userState = useSelector((state) => state.userState);
    const lotAssociationState = useSelector(
        (state) => state.lotAssociationState
    );
    const tooltipContent = lotAssociationState && lotAssociationState.lotTooltipContent;
    const { userDetails } = userState;
    const { busy, errors } = userDetails;

    const onSubmit = () => {
        const loginObj = createloginAction({
            emailAddress: userDetails.emailAddress,
            password: userDetails.password,
            token: userDetails.token,
        });

        CookieService.deleteCookie('adminLotId');

        if (userDetails.remember) {
            CookieService.setCookie('remember', userDetails.emailAddress, 7);
        }

        dispatch(loginObj);
    };

    const onUpdate = (userData) => {
        dispatch(updateUserDetailsAction(userData));
    };

    if (
        CookieService.getCookie('remember') !== '' &&
        CookieService.getCookie('remember') !== null &&
        userDetails.emailAddress === null
    ) {
        dispatch(
            updateUserDetailsAction({ emailAddress: CookieService.getCookie('remember') })
        );
    }

    const loadLotToolTip = () => {
        dispatch(createFetchLotToolTipContentAction());
    };

    useEffect(() => {
        if (!tooltipContent) {
            loadLotToolTip();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tooltipContent]);
    return (
        <React.Fragment>
            <Title title={Constants.pages.login.pageTitle} />
            <main
                className="main main--container"
                data-testid="login-container"
            >
                <Container>
                    <Row className="justify-content-md-center" md={12}>
                        <Col lg={7} md={10} sm={12}>
                            <LoginForm
                                onSubmit={onSubmit}
                                onUpdate={onUpdate}
                                busy={busy}
                                errors={errors}
                                message={lotAssociationState && lotAssociationState.loginMessage}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
        </React.Fragment>
    );
};

export default Login;
