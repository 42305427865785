import ActionTypes from 'redux/actions/ActionTypes';

export function createloginAction(userData) {
    return {
        type: ActionTypes.Login,
        payload: userData,
    }
};

export function createLoginFromADOEventAction(tokenData) {
    return {
        type: ActionTypes.ADOLogin,
        payload: tokenData,
    }
};

export function updateUserDetailsAction(data) {
    return {
        type: ActionTypes.UpdateUserDetails,
        payload: data,
    }
};

export function createRefreshFromCookieAction() {
    return {
        type: ActionTypes.CreateTokenRefresh,
    }
};

export function createSaveUsersDestinationForAfterLoginAction(location) {
    return {
        type: ActionTypes.SaveUsersDestinationForAfterLogin,
        payload: location,
    }
};

export function handleTokenRefreshSuccessAction(data) {
    return {
        type: ActionTypes.HandleTokenRefreshSuccess,
        payload: data,
    }
};

export function handleTokenRefreshErrorAction() {
    return {
        type: ActionTypes.HandleTokenRefreshError,
    }
};

export function handleApiFatalErrorAction() {
    return {
        type: ActionTypes.ApiFatalError,
    }
};

export function handleRedirectForbiddenErrorAction(redirectType) {
    return {
        type: ActionTypes.RedirectForbidden,
        payload: redirectType,
    }
};

export function handleRedirect404ErrorAction(responseData) {
    return {
        type: ActionTypes.RedirectError404,
        payload: responseData,
    }
};
