import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { LoadingScreen } from 'ComponentLibrary';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, redirect, useLocation, useMatch } from 'react-router-dom';
import {
  useAwaitTokenHasBeenRefreshed,
  useIsAdmin,
  useCurrentPage,
} from 'utilities/HooksAndSelectors';
import { CSSTransition } from 'react-transition-group';
import { createSaveUsersDestinationForAfterLoginAction } from 'redux/actions/Login/LoginActions';


const WithRedirect = (props) => {
  const { page, children, adminOnly, path } = props;

  const refreshed = useAwaitTokenHasBeenRefreshed();
  const user = useSelector((reduxState) => reduxState.userState);
  const currentPage = useCurrentPage();
  const userDetails = user ? user.userDetails : null;
  const afterSuccessfulLogin = userDetails
    ? userDetails.afterSuccessfulLogin
    : null;
  const location = useLocation();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  console.log(`i am the with redirect ${currentPage} ${page} -${path}-`)

  const match = useMatch({
    path: path || "",
    exact: true,
  });

  if (!isAdmin && adminOnly) {

    return <Navigate to={('/dashboard')} />;
  }
  if (!refreshed) {
    return null;
  }

  const { pathname } = location;
  const isExternalRedirect =
    pathname.indexOf('/financing/loanapplication') >= 0 ||
    pathname.indexOf('/envision') >= 0 ||
    pathname.indexOf('/designcenter/confirmedselections') >= 0 ||
    pathname.indexOf('/wellsfargo') >= 0;

  if (
    !isExternalRedirect &&
    currentPage !== page &&
    refreshed &&
    page !== 'admin'
  ) {
    switch (currentPage) {
      case 'dashboard':
        const whereIAmGoing =
          afterSuccessfulLogin !== null &&
            afterSuccessfulLogin !== undefined &&
            afterSuccessfulLogin !== '' &&
            afterSuccessfulLogin !== '/'
            ? afterSuccessfulLogin
            : `/dashboard${location.pathname.startsWith('/dashboard')
              ? location.pathname.substr(10)
              : ''
            }`;
        console.log('3');
        return <Navigate to={whereIAmGoing} />;//<Redirect to={whereIAmGoing} />;
        return null;
      case 'findLot':
        console.log('4');
        return <Navigate to='/find-lot' />;//<Redirect to='/find-lot' />;
      case 'lotAssociation':
        console.log('5');
        return <Navigate to='/lot-association' />;
      default:
        if (
          location.pathname + window.location.hash !== afterSuccessfulLogin &&
          location.pathname.indexOf('login') < 0
        ) {
          dispatch(
            createSaveUsersDestinationForAfterLoginAction(
              location.pathname + window.location.hash
            )
          );
        }
        console.log('6');
        return <Navigate to={`/login${location.search}`} />;
        return redirect(`/login${location.search}`);//<Redirect to={` / login${ location.search } `} />;
    }
  }
  console.log('7');
  const isAnimatable = true;
  console.dir('what are 7 props');
  console.dir(props);
  console.dir('what are 7 match');
  console.dir(match)
  return (
    <Suspense
      fallback={<LoadingScreen exit={false} variant={null} color={null} />}
    >
      <CSSTransition
        in={match != null}
        appear={match != null}
        timeout={400}
        classNames={isAnimatable ? 'slide' : ''}
        exit={match === null}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    </Suspense>
  );

};

WithRedirect.propTypes = {
  adminOnly: PropTypes.bool,
};
WithRedirect.defaultProps = {
  adminOnly: false,
};
export default WithRedirect;
