import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import './Modal.scss';
import { isIOS } from 'react-device-detect';
import PropTypes from 'prop-types';
//import 'bootstrap/dist/css/bootstrap.min.css';

export default function MIModal(props) {
  const {
    show,
    closeModal,
    isHeader,
    title,
    children,
    footer,
    customClass,
    modalSize,
    centered,
    isVideo,
    stopScroll,
    forceDecision,
  } = props

  const handleTouchMove = (e) => {
    e.preventDefault()
  }

  useEffect(() => {
    if (show && stopScroll && isIOS) {
      window.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      })
    }
    return () => {
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [show, stopScroll])

  const extraProps = {}
  const modalOptions = {}
  if (forceDecision) {
    extraProps.backdrop = 'static'
    extraProps.keyboard = false
    modalOptions.dismissible = false
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
      }}
      size={modalSize}
      className={customClass}
      centered={centered}
      data-testid='mi-modal-container'
      {...extraProps}
    >
      {!isVideo ? (
        <React.Fragment>
          {isHeader && (
            <Modal.Header closeButton>
              {title && <Modal.Title>{title}</Modal.Title>}
            </Modal.Header>
          )}
          <Modal.Body>{children}</Modal.Body>
          {footer && <Modal.Footer>{footer}</Modal.Footer>}
        </React.Fragment>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </Modal>
  )
}

MIModal.defaultProps = { forceDecision: false };

MIModal.propTypes = {
  forceDecision: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
}
