import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    createGetLotByIdAction,
    createUpdateLotAssociationIdAction,
    createClearLotErrorsAction,
} from 'redux/actions/LotAssociation/LotAssociationActions';
import Constants from 'Constants';
import { CookieService } from 'services';
import {
    createCloseFindLotPageAction,
    createLogOutAction,
} from 'redux/actions/ProfileAndSettings/ProfileAndSettingsActions';
import {
    createGetCommunitiesAction,
    createGetDivisionsAction,
} from 'redux/actions/Admin';
import Utils from 'utilities/Utils';
import { Input, Error, Button, ButtonStyles, RadioSet } from 'ComponentLibrary';
import './LotIdSearch.scss';
import { isIOS } from 'react-device-detect';

const LotIdSearch = (props) => {
    const dispatch = useDispatch();
    const { mode, setMode, division, setDivision, community, setCommunity, setFourDigit, setTwelveDigit, fourDigit, twelveDigit } = props;
    const [localErrors, setLocalErrors] = useState([])
    const userState = useSelector((state) => state.userState);
    const homesState = useSelector((state) => state.homesState);
    const { divisions, communities } = homesState;
    const { userDetails } = userState;
    const { accessToken, busy } = userDetails;
    const lotAssociationState = useSelector(
        (state) => state.lotAssociationState
    );
    const { errors, lotId } = lotAssociationState;
    const navigate = useNavigate();



    const submitLotAssociationDetails = () => {
        let valid = true;
        if (mode !== '12 digit job id') {
            if (!haveDivisionAndCommunities) {
                valid = false;
                if (division === -1) {
                    setLocalErrors([{ field: 'division', message: Constants.pages.findLot.divisionError }])
                } else {
                    setLocalErrors([{ field: 'community', message: Constants.pages.findLot.communityError }])
                }
            } else if (!Utils.isNumeric(lotId) || lotId.toString().length === 0) {
                valid = false;
                setLocalErrors([{ field: 'lotid', message: Constants.pages.findLot.lotIdError }])
            }
        }
        if (mode === '12 digit job id') {
            if (!Utils.isNumeric(lotId) || lotId.toString().length !== 12) {
                valid = false;
                setLocalErrors([{ field: 'lotid', message: Constants.pages.findLot.lotIdError }])
            } else {
                setLocalErrors(null);
            }
        }
        if (valid) {
            setLocalErrors([]);
            if (mode === '12 digit job id') {
                dispatch(createGetLotByIdAction(lotAssociationState.lotId));
            } else {
                let digits = lotAssociationState.lotId;
                while (digits.length < 4) {
                    digits = `0${digits}`;
                }
                const fullLotId = parseInt(`${community.substr(0, 8)}${digits}`, 10);
                dispatch(createGetLotByIdAction(fullLotId));
            }
        }
        return false;
    };

    const handleChange = (e) => {
        const { value } = e.target;
        if (mode === '12 digit job id') {
            setTwelveDigit(value);
        } else {
            setFourDigit(value);
        }
        dispatch(createUpdateLotAssociationIdAction(value));
    };

    let lotIdFieldError;
    if (errors && errors.form) {
        lotIdFieldError = [{ message: Constants.global.genericError }];
    }

    if (!divisions) {
        dispatch(createGetDivisionsAction());
    }
    if ((!communities || !communities[division]) && division !== '-1' && division !== -1) {
        dispatch(createGetCommunitiesAction(division));
    }

    const lotIdError = localErrors && localErrors.filter(({ field }) => field === 'lotid')[0];
    const divisionError = localErrors && localErrors.filter(({ field }) => field === 'division')[0];
    const communityError = localErrors && localErrors.filter(({ field }) => field === 'community')[0];
    CookieService.setCookie('searchMode', mode);
    const haveDivisionAndCommunities = division && community !== -1 && communities[division] && Array.isArray(communities[division]);

    return (
        <div className="lot-id-search find-lot-block" data-testid="lot-id-search">
            <form
                noValidate
                className="form"
                data-testid="lot-id-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    submitLotAssociationDetails();
                    return false;
                }}
            >

                {errors &&
                    errors.form &&
                    errors.form.map((error, index) => (
                        <Error className="error--margin-bottom" key={index}>{error.message}</Error>
                    ))}

                <RadioSet
                    id="lot-id-search"
                    testId="lot-id-radio"
                    grid
                    hiddenLabel
                    groupName="mode"
                    label={Constants.pages.findLot.searchType}
                    initialValue={mode}
                    options={Constants.pages.findLot.searchTypesForLotId}
                    onChange={(value) => {
                        setMode(value);
                        CookieService.setCookie('searchMode', value);
                        dispatch(createClearLotErrorsAction());
                    }}
                />
                {mode === '4 digit lot number' && Array.isArray(divisions) && (
                    <>
                        <div className="field-wrap" data-testid="4-digit-wrapper">
                            <label htmlFor="division" className="label">Division*</label>
                            <div className="input-wrap input-wrap--select input-wrap--select-like-input">
                                <select
                                    required
                                    data-testid="division-select"
                                    id="division"
                                    name="division"
                                    value={division}
                                    className="input input--select select select--small-padding"
                                    onChange={(e) => {
                                        setDivision(e.currentTarget.value);
                                        if (e.currentTarget.value !== -1 && e.currentTarget.value !== '-1') {
                                            setLocalErrors(localErrors.filter((error) => error.field !== 'division'))
                                        }
                                    }}
                                >
                                    <option value={-1}>
                                        {Constants.pages.findLot.divisionSelectPlaceholder}
                                    </option>
                                    {divisions.map(({ name, divisionId, sitecoreId }) => <option key={sitecoreId} value={sitecoreId} data-testid="division-select-option">{name}</option>)}
                                </select>
                            </div>
                            {divisionError &&
                                <Error className="error--below" testId="4-digit-division-error">{divisionError.message}</Error>
                            }
                        </div>

                        {communities[division] && Array.isArray(communities[division]) && (
                            <>
                                <div className="field-wrap" data-testid="4-digit-communities-wrapper">
                                    <label htmlFor="community" className="label">
                                        Community*
                                    </label>
                                    <div className="input-wrap input-wrap--select input-wrap--select-like-input">
                                        <select
                                            onChange={(e) => {
                                                setCommunity(e.currentTarget.value);
                                                if (e.currentTarget.value !== -1 && e.currentTarget.value !== '-1') {
                                                    setLocalErrors(localErrors.filter((error) => error.field !== 'community'))
                                                }
                                            }}
                                            value={community}
                                            id="community"
                                            data-testid="community-select"
                                            name="community"
                                            className="input input--select select select--small-padding"
                                            required
                                        >
                                            <option value={-1}>{Constants.pages.findLot.communitySelectPlaceholder}</option>
                                            {communities[division].map(({ name, communityId, divisionId, sitecoreId }) => name ? <option key={communityId} value={communityId} data-testid="community-select-option">{name}</option> : null)}
                                        </select>
                                    </div>
                                </div>
                                {communityError &&
                                    <Error className="error--below" testId="4-digit-community-error">{communityError.message}</Error>
                                }
                            </>
                        )}
                    </>
                )}
                {mode === '12 digit job id' && (
                    <>
                        <Input
                            type="number"
                            max={mode === '12 digit job id' ? 999999999999 : 9999}
                            maxLength={mode === '12 digit job id' ? '12' : '4'}
                            label={mode === '12 digit job id' ? Constants.lotAssociation.placeholder : Constants.pages.findLot.placeholder}
                            testId="findLotId12"
                            id="lotId"
                            autofocus={!isIOS}
                            name="lotId-12"
                            placeholder={mode === '12 digit job id' ? Constants.lotAssociation.placeholder : Constants.pages.findLot.placeholder}
                            errors={lotIdFieldError}
                            onChange={handleChange}
                            initialValue={twelveDigit}
                        />
                        {lotIdError && (<Error className="error--below" testId="lot-12-id-error">{lotIdError.message}</Error>)}
                    </>
                )}
                {mode !== '12 digit job id' && haveDivisionAndCommunities && (
                    <>
                        <Input
                            type="number"
                            max={mode === '12 digit job id' ? 999999999999 : 9999}
                            maxLength={mode === '12 digit job id' ? '12' : '4'}
                            label={mode === '12 digit job id' ? Constants.lotAssociation.placeholder : Constants.pages.findLot.placeholder}
                            testId="findLotId4"
                            id="lotId"
                            autofocus={!isIOS}
                            name="lotId-4"
                            placeholder={mode === '12 digit job id' ? Constants.lotAssociation.placeholder : Constants.pages.findLot.placeholder}
                            errors={lotIdFieldError}
                            onChange={handleChange}
                            initialValue={fourDigit}
                        />
                        {lotIdError && (<Error className="error--below" testId="lot-4-id-error">{lotIdError.message}</Error>)}
                    </>
                )}
                <div className="find-lot-buttons">
                    <Button
                        as="button"
                        testId="submit-lot-button"
                        style={ButtonStyles.PrimaryButton}
                        text={Constants.pages.adminLotAssociation.submit}
                        submit
                        disabled={busy}
                    />
                    <Button
                        as="link"
                        testId="find-lot-cancel-button"
                        style={ButtonStyles.SecondaryButton}
                        addClass="find-lot-cancel"
                        text={Constants.pages.adminLotAssociation.cancel}
                        onClick={(e) => {
                            const c = CookieService.getCookie('adminLotId');
                            if (c) {
                                const action = createCloseFindLotPageAction(
                                    CookieService.getCookie('adminLotId')
                                );
                                dispatch(action);
                                navigate('/dashboard');
                            } else {
                                dispatch(createLogOutAction(accessToken, true));
                                navigate('/login?logout=true');
                            }

                            e.preventDefault();
                            return false;
                        }}
                    />
                </div>
            </form>
        </div>
    );
};
export default LotIdSearch;
