import React from 'react';
import { Heading, Icon } from 'ComponentLibrary';
import { Accordion, Card } from 'react-bootstrap';
import Constants from 'Constants';
import TaskSetAccordion from '../TaskSetAccordion';
import './MITasksAccordionCard.scss';

const MITasksAccordionCard = (props) => {
    const { completeTasks, upcomingTasks, mode, handleToggle, isOpen } = props;
    const checklistModalText = Constants.checklistModal;

    return (
        <Card className="mi-checklist-card" data-testid="mi-checklist-card">
            <Accordion.Button
                className="mi-checklist-toggle"
                variant="link"
                onClick={handleToggle}
                eventKey="0"
                data-testid="mi-checklist-toggle"
            >
                <div className="mi-checklist-toggle-content">
                    <Heading like="h5" as="h5">
                        {checklistModalText.miTasksAccordion.title}
                    </Heading>
                    <Icon
                        icon="skinnyarrow"
                        color={isOpen ? 'white' : 'aqua'}
                        circle={isOpen ? 'boston-blue' : null}
                        border="boston-blue"
                        extraClasses="mi-checklist-toggle-accordion-icon"
                        testId="mi-checklist-toggle-accordion-icon"
                    />
                </div>
            </Accordion.Button>
            <Accordion.Collapse eventKey="0">
                <div className="mi-checklist-toggle-collapse-container">
                    <TaskSetAccordion
                        label={checklistModalText.upcomingText}
                        tasks={upcomingTasks}
                        mode={mode}
                        testId="upcoming-task-set"
                    />
                    <TaskSetAccordion
                        label={checklistModalText.completedText}
                        tasks={completeTasks}
                        mode={mode}
                        testId="completed-task-set"
                    />
                </div>
            </Accordion.Collapse>
        </Card>
    );
};

export default MITasksAccordionCard;
