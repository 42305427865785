import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Button } from 'react-bootstrap';
import './AccordionGroupHeader.scss';
import Constants from 'Constants';
import { Text } from 'ComponentLibrary';

export default function AccordionGroupHeader(props) {
    const { type } = props;
    const [toggleState, setToggleState] = useState(false);

    const toggleHeaderText = () => {
        setToggleState(!toggleState);
    };
    const headerConstants = Constants.dashboard.progress.accordion.text[type];

    return (
        <Accordion.Button
            as={Button}
            data-testid="accordion-group-toggle"
            variant="accordion-group-header"
            eventKey="0"
            onClick={toggleHeaderText}
        >
            <div
                className="accordion-group-header-container text--museo"
                data-testid="accordion-group-header-container"
            >
                <Text type="block" variant="small" family="museo">
                    <span>
                        {!toggleState
                            ? headerConstants.show
                            : headerConstants.hide}
                    </span>
                </Text>
            </div>
        </Accordion.Button>
    );
}

AccordionGroupHeader.defaultProps = {
    type: null,
};

AccordionGroupHeader.propTypes = {
    type: PropTypes.string,
};
