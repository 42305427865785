import React from 'react';
import PropTypes from 'prop-types';
import './NotificationsDropdownCard.scss';
import { Accordion, Card } from 'react-bootstrap';
import { Text, Icon, FlexibleLink } from 'ComponentLibrary';
import Utils from 'utilities/Utils';
import { useDispatch } from 'react-redux';
import { createCloseNotificationsDropdownAction } from 'redux/actions/Notifications/NotificationsActions';
import { useIsAdmin } from 'utilities/HooksAndSelectors';

export default function NotificationsDropdownCard(props) {
  const { notification, handleClick, isOpen, read, showAdminInfo = false } = props;
  const d = new Date();
  const dispatch = useDispatch();
  const dateHalf = notification.sentDate.split('T')[0];
  const datePieces = dateHalf.split('-');
  const notificationId = notification.notificationId;

  const isAdmin = useIsAdmin();

  d.setUTCFullYear(datePieces[0]); // 2020-08-03T00:00:00
  d.setUTCDate(datePieces[2]);
  d.setUTCMonth(datePieces[1] - 1);

  const dateString = `${Utils.day(d)}, ${Utils.month(d)} ${d.getDate()}, ${d.getFullYear()}`;//d.toLocaleDateString();

  const updateAccordionState = (e) => {
    e.preventDefault();
    if (handleClick) {
      handleClick(notification);
    }
    return false;
  };
  const {
    linkUrl,
    title,
    bodyText,
    callToActionText,
    triggerScript,
    sendDateScript,
  } = notification;
  const external = linkUrl && linkUrl.indexOf('://') >= 0;
  return (
    <Accordion.Item eventKey={notificationId}>
      <div className={`notifications-card-container ${!handleClick && 'notifications-card-container--standalone'}`} data-testid="notification-card-container">
        <Card>
          <Accordion.Button variant="link" eventKey={notificationId} onClick={updateAccordionState} data-testid={`notification-dropdown-card-toggle-${notificationId}`}>
            <div className={`notifications-dropdown-card-header notifications-dropdown-card-header--${!read && 'unread'} ${isOpen ? 'open' : ''}`}>
              <Text variant="small" color='tundora'>
                {title}
              </Text>
              <span className="notifications-dropdown-card-sent-text">
                <Text variant="extra-small">{dateString}</Text>
              </span>
              {handleClick && (
                <Icon
                  icon="skinnyarrow"
                  color={isOpen ? 'white' : 'aqua'}
                  circle={isOpen ? 'boston-blue' : 'neutral-light'}
                />)}
            </div>
          </Accordion.Button>
          <Accordion.Collapse eventKey={notificationId}>
            <div className={`notifications-dropdown-card-content notifications-dropdown-card-content--${!read && 'read'}`}>
              <Text variant="small" color="body-text">
                {bodyText}
              </Text>
              {linkUrl && (
                <FlexibleLink
                  isInternal={!external}
                  handleClick={!external ? () => { dispatch(createCloseNotificationsDropdownAction()) } : ''}
                  classList="notifications-dropdown-link"
                  testId={external ? 'notifications-dropdown-link-external' : 'notifications-dropdown-link-internal'}
                  linkDetails={{ url: linkUrl }}
                >
                  <Text variant="small" color="aqua">
                    {callToActionText}
                  </Text>
                  <Icon size={2} color="aqua" icon="doublearrow" />
                </FlexibleLink>
              )}
              {isAdmin && showAdminInfo && (
                <div>
                  <p>TriggerScript: {triggerScript}</p>
                  <p>SendDateScript: {sendDateScript}</p>
                </div>
              )}
            </div>
          </Accordion.Collapse>
        </Card>
      </div>
    </Accordion.Item>
  );
}

NotificationsDropdownCard.defaultProps = {
  handleClick: null,
};

NotificationsDropdownCard.propTypes = {
  notification: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  read: PropTypes.bool.isRequired,
};
